import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'gatsby'
import React, { useState } from 'react'

export default function MenubarComponent({ active }) {
  const [menuOpen, setMenuOpen] = useState(false)

  const handleHamburgerMenu = () => {
    setMenuOpen((state) => !state)
  }

  return (
    <>
      {menuOpen ? (
        <div
          className="bg-black bg-opacity-90 z-50 fixed top-0 left-0 right-0 bottom-0 flex flex-col text-white items-center justify-center text-4xl gap-4"
          onClick={() => setMenuOpen(false)}
        >
          <div
            className="hover:text-yellow-400 cursor-pointer"
            onClick={handleHamburgerMenu}
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </div>
          <Link
            className={`hover:text-yellow-400 ${
              active === 'home' ? 'text-yellow-400 underline' : null
            }`}
            to="/"
            onClick={() => setMenuOpen(false)}
          >
            Strona Główna
          </Link>
          <Link
            className={`hover:text-yellow-400 ${
              active === 'about' ? 'text-yellow-400 underline' : null
            }`}
            to="/about"
            onClick={() => setMenuOpen(false)}
          >
            O nas
          </Link>
          <Link
            className={`hover:text-yellow-400 ${
              active === 'cars' ? 'text-yellow-400 underline' : null
            }`}
            to="/cars"
            onClick={() => setMenuOpen(false)}
          >
            Oferta
          </Link>
        </div>
      ) : null}

      <div className="fixed top-0 z-40 bg-white flex menubar--container w-full justify-center shadow-lg">
        <div className="inline-flex items-center justify-between h-16 px-6 md:px-8 xl:px-16 w-full max-w-screen-2xl ">
          <div className="menubar--left ">
            <span className="text-xl font-semibold ">
              <Link to="/">
                <b>M-AUTO</b>
              </Link>
            </span>
          </div>
          <div className="menubar--right">
            <div
              className="p-4 cursor-pointer flex md:hidden hover:text-gray-700"
              onClick={handleHamburgerMenu}
            >
              <div>
                <FontAwesomeIcon icon={faBars} size="lg" />
              </div>
            </div>

            <div className="text-gray-500 hidden md:flex gap-x-4 md:gap-x-7 text-sm items-center uppercase font-medium">
              <Link
                className={`menu--item hover:text-gray-800 ${
                  active === 'home' ? 'text-black underline  ' : null
                }`}
                to="/"
              >
                Strona Główna
              </Link>
              <Link
                className={`menu--item hover:text-gray-800 ${
                  active === 'about' ? 'text-black underline  ' : null
                }`}
                to="/about"
              >
                O nas
              </Link>
              <Link
                className={`menu--item hover:text-gray-800 ${
                  active === 'cars' ? 'text-black underline  ' : null
                }`}
                to="/cars"
              >
                Oferta
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
