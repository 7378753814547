import React from 'react'
import { Link } from 'gatsby'

export default function FooterComponent() {
  return (
    <footer className="w-full">
      <div className="text-white mx-auto px-8 md:px-16 lg:px-22 xl:px-16 2xl:px-8 max-w-screen-2xl py-16 md:py-24 sm:px-16 md:px-24 lg:px-28 grid grid-cols-1 text-center gap-8 md:text-left md:gap-4 md:grid-cols-2 lg:grid-cols-4">
        <div>
          <h2 className="pb-3 text-lg">Dane kontaktowe</h2>
          <h3 className="text-sm mb-2">M-Auto S.C.</h3>
          <h3 className="text-sm mb-2">Zebrzydowa 242</h3>
          <h3 className="text-sm mb-2">59-730 Nowogrodziec</h3>
          <h3 className="text-sm mb-2">NIP: 6121862270</h3>
        </div>
        <div>
          <h2 className="pb-3 text-lg">Godziny otwarcia</h2>
          <h3 className="text-sm mb-2">Pon-Pt: 9:00-18:00</h3>
          <h3 className="text-sm mb-2">Sobota: 10:00-15:00</h3>
          <h3 className="text-sm mb-2">Niedziela: 10:00-14:00</h3>
        </div>
        <div>
          <h2 className="pb-3 text-lg">Kontakt</h2>
          <h3 className="text-sm mb-2">+48 669 660 446</h3>
          <h3 className="text-sm mb-2">+48 725 730 436</h3>
          <h3 className="text-sm mb-2">pthumauto@gmail.com</h3>
        </div>
        <div>
          <h2 className="pb-3 text-lg">Linki</h2>
          <h3 className="text-sm mb-2">
            <a href="https://mauto.otomoto.pl/">otomoto</a>
          </h3>
          <h3 className="text-sm mb-2">
            <a href="https://www.dzonyprogramuje.com/">dzonyprogramuje</a>
          </h3>
          <h3 className="text-sm mb-2">
            <Link to="/policy">polityka prywatności</Link>
          </h3>
        </div>
      </div>
    </footer>
  )
}
